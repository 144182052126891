import React, { useState, useEffect } from 'react';
import { Camera, Clock, CheckCircle, Share2, Gift, ChevronDown, MessageCircle } from 'lucide-react';

const LandingPage = () => {
  // Estados para gerenciar a aplicação
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventType, setEventType] = useState('');
  const [duration, setDuration] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [recentBookings, setRecentBookings] = useState([]);
  const [availableSlots, setAvailableSlots] = useState(5);
  const [countdown, setCountdown] = useState({ hours: 5, minutes: 59, seconds: 59 });
  const [showModal, setShowModal] = useState(false);
  
  // Simular bookings recentes para FOMO
  useEffect(() => {
    const cities = ['São Paulo', 'Rio de Janeiro', 'Belo Horizonte', 'Curitiba', 'Florianópolis', 'Brasília', 'Salvador'];
    const events = ['Casamento', 'Aniversário', 'Formatura', 'Festa Corporativa'];
    
    const interval = setInterval(() => {
      if (Math.random() > 0.7) {
        const newBooking = {
          name: `${['João', 'Maria', 'Pedro', 'Ana', 'Lucas', 'Julia'][Math.floor(Math.random() * 6)]} ${['S.', 'M.', 'L.', 'R.', 'C.'][Math.floor(Math.random() * 5)]}`,
          city: cities[Math.floor(Math.random() * cities.length)],
          event: events[Math.floor(Math.random() * events.length)],
          time: `${Math.floor(Math.random() * 10) + 1} min atrás`
        };
        
        setRecentBookings(prev => [newBooking, ...prev.slice(0, 4)]);
      }
    }, 8000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Countdown para promoção (FOMO)
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
        } else if (prev.hours > 0) {
          return { hours: prev.hours - 1, minutes: 59, seconds: 59 };
        }
        return prev;
      });
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Diminuir vagas disponíveis aleatoriamente
  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() > 0.9 && availableSlots > 1) {
        setAvailableSlots(prev => prev - 1);
      }
    }, 45000);
    
    return () => clearInterval(interval);
  }, [availableSlots]);

  // Simular efeito de remarketing salvando dados no localStorage
  useEffect(() => {
    const savedEmail = localStorage.getItem('visitorEmail');
    if (savedEmail) {
      setEmail(savedEmail);
    }
    
    // Registrar visita para remarketing
    const visited = localStorage.getItem('visited');
    if (!visited) {
      localStorage.setItem('visited', 'true');
      localStorage.setItem('firstVisit', new Date().toISOString());
    }
    
    // Mostrar modal de exit intent após alguns segundos para novos visitantes
    const timer = setTimeout(() => {
      if (!visited && !showModal) {
        setShowModal(true);
      }
    }, 15000);
    
    return () => clearInterval(timer);
  }, []);
  
  // Função para tratar envio do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Salvar email para remarketing
    if (email) {
      localStorage.setItem('visitorEmail', email);
    }
    
    // Simular envio do formulário
    setIsFormSubmitted(true);
    
    // Preparar dados para WhatsApp
    const message = `Olá! Gostaria de fazer uma cotação para a Plataforma 360° para ${eventType} no dia ${eventDate}. Duração: ${duration}h. Meu nome é ${name} e meu email é ${email}.`;
    const whatsappUrl = `https://wa.me/5511999999999?text=${encodeURIComponent(message)}`;
    
    // Abrir WhatsApp após 2 segundos
    setTimeout(() => {
      window.open(whatsappUrl, '_blank');
    }, 2000);
  };
  
  // Formatação do número de telefone
  const formatPhone = (value) => {
    const numeric = value.replace(/\D/g, '');
    let formatted = numeric;
    
    if (numeric.length > 2) {
      formatted = `(${numeric.substring(0, 2)}) ${numeric.substring(2)}`;
    }
    
    if (numeric.length > 7) {
      formatted = `(${numeric.substring(0, 2)}) ${numeric.substring(2, 7)}-${numeric.substring(7, 11)}`;
    }
    
    return formatted;
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-green-900 via-green-800 to-green-700 text-white">
      {/* Header */}
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Camera size={32} className="text-green-400" />
          <h1 className="text-2xl font-bold">Foca no Zoom</h1>
        </div>
        <div className="hidden md:flex space-x-4 items-center">
          <a href="#como-funciona" className="hover:text-yellow-400 transition">Como Funciona</a>
          <a href="#depoimentos" className="hover:text-yellow-400 transition">Depoimentos</a>
          <a href="#contato" className="bg-green-400 text-green-900 px-4 py-2 rounded-full font-bold hover:bg-green-300 transition">Reservar Agora</a>
        </div>
        <button className="md:hidden text-white">
          <ChevronDown size={24} />
        </button>
      </header>
      
      {/* Hero Section */}
      <section className="py-12 md:py-20 px-4">
        <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h1 className="text-4xl md:text-6xl font-extrabold mb-4 leading-tight">
              Transforme Sua Festa com Memórias <span className="text-green-400">360° Inesquecíveis</span>
            </h1>
            <p className="text-xl mb-6 text-gray-300">
              Surpreenda seus convidados com a experiência fotográfica mais inovadora do momento. Crie momentos únicos que serão compartilhados nas redes sociais!
            </p>
            
            {/* FOMO - Elementos de Urgência */}
            <div className="bg-green-800 p-4 rounded-lg mb-6 border border-green-600">
              <div className="flex items-center mb-2">
                <Clock size={20} className="text-green-400 mr-2" />
                <p className="font-bold">OFERTA ESPECIAL POR TEMPO LIMITADO:</p>
              </div>
              <p className="text-2xl font-bold mb-2">
                <span className="line-through text-gray-400">R$500</span>{' '}
                <span className="text-green-400">R$400</span> /hora
              </p>
              <div className="flex justify-between text-sm bg-green-900 p-2 rounded">
                <div className="text-center">
                  <div className="text-xl font-bold">{countdown.hours}</div>
                  <div>Horas</div>
                </div>
                <div className="text-center">
                  <div className="text-xl font-bold">{countdown.minutes}</div>
                  <div>Minutos</div>
                </div>
                <div className="text-center">
                  <div className="text-xl font-bold">{countdown.seconds}</div>
                  <div>Segundos</div>
                </div>
              </div>
            </div>
            
            <div className="flex flex-col sm:flex-row gap-4">
              <a href="#contato" className="bg-green-400 text-green-900 px-6 py-3 rounded-full font-bold text-center hover:bg-green-300 transition transform hover:scale-105">
                GARANTIR MINHA RESERVA
              </a>
              <a href="#como-funciona" className="border border-white px-6 py-3 rounded-full font-bold text-center hover:bg-white hover:text-purple-900 transition">
                COMO FUNCIONA
              </a>
            </div>
          </div>
          
          <div className="md:w-1/2 relative">
            <div className="bg-gradient-to-tr from-lime-500 to-green-400 p-1 rounded-lg rotate-3 shadow-xl">
              <div className="bg-green-900 p-2 rounded-lg">
                <img 
                  src="/api/placeholder/600/400" 
                  alt="Plataforma 360° em ação" 
                  className="rounded"
                />
              </div>
            </div>
            
            {/* FOMO - Vagas limitadas */}
            <div className="absolute -bottom-5 -left-5 bg-red-600 text-white px-4 py-2 rounded-full font-bold shadow-lg transform -rotate-12">
              Restam apenas {availableSlots} vagas este mês!
            </div>
          </div>
        </div>
      </section>
      
      {/* FOMO - Reservas recentes */}
      <section className="bg-green-800 py-4 overflow-hidden">
        <div className="container mx-auto">
          <div className="flex items-center gap-4 animate-marquee">
            {recentBookings.map((booking, i) => (
              <div key={i} className="flex items-center bg-green-700 px-4 py-2 rounded-full flex-shrink-0">
                <div className="w-2 h-2 bg-green-400 rounded-full mr-2"></div>
                <p className="text-sm">
                  <span className="font-bold">{booking.name}</span> de {booking.city} reservou para {booking.event} • <span className="text-gray-300">{booking.time}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* Como Funciona */}
      <section id="como-funciona" className="py-16 px-4">
        <div className="container mx-auto max-w-6xl">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
            <span className="text-green-400">Como Funciona</span> Nossa Plataforma 360°
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-green-800 p-6 rounded-lg text-center transform transition hover:scale-105">
              <div className="bg-green-700 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Camera size={32} className="text-green-400" />
              </div>
              <h3 className="text-xl font-bold mb-2">Experiência Completa</h3>
              <p className="text-gray-300">Nossa plataforma permite que seus convidados gravem vídeos 360° com efeitos especiais e música.</p>
            </div>
            
            <div className="bg-green-800 p-6 rounded-lg text-center transform transition hover:scale-105">
              <div className="bg-green-700 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Share2 size={32} className="text-green-400" />
              </div>
              <h3 className="text-xl font-bold mb-2">Compartilhamento Instantâneo</h3>
              <p className="text-gray-300">Seus convidados recebem os vídeos na hora e podem compartilhar nas redes sociais com a sua marca.</p>
            </div>
            
            <div className="bg-green-800 p-6 rounded-lg text-center transform transition hover:scale-105">
              <div className="bg-green-700 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Gift size={32} className="text-green-400" />
              </div>
              <h3 className="text-xl font-bold mb-2">Memória Eterna</h3>
              <p className="text-gray-300">Todos os vídeos são compilados e você recebe uma galeria exclusiva da sua festa após o evento.</p>
            </div>
          </div>
        </div>
      </section>
      
      {/* Formulário de Cotação */}
      <section id="contato" className="py-16 px-4 bg-green-800">
        <div className="container mx-auto max-w-6xl">
          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                Garanta Agora sua <span className="text-green-400">Foca no Zoom</span>
              </h2>
              
              <p className="mb-6 text-lg">
                Preencha o formulário para garantir sua reserva com o preço promocional de <span className="text-green-400 font-bold">R$400/hora</span> (desconto de R$100).
              </p>
              
              <div className="bg-green-900 p-4 rounded-lg mb-6 border border-green-700">
                <h3 className="font-bold mb-2 text-green-400">Por que reservar agora:</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle size={20} className="text-green-400 mr-2 flex-shrink-0 mt-1" />
                    <span>Preço promocional garantido (economia de R$100/hora)</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle size={20} className="text-green-400 mr-2 flex-shrink-0 mt-1" />
                    <span>Reserve com apenas 50% de entrada</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle size={20} className="text-green-400 mr-2 flex-shrink-0 mt-1" />
                    <span>100 props exclusivos para fotos</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle size={20} className="text-green-400 mr-2 flex-shrink-0 mt-1" />
                    <span>Cancele sem custo até 30 dias antes do evento</span>
                  </li>
                </ul>
              </div>
              
              <div className="bg-green-400 text-green-900 p-4 rounded-lg font-bold">
                Não perca tempo! Restam apenas {availableSlots} vagas na agenda deste mês!
              </div>
            </div>
            
            <div>
              {!isFormSubmitted ? (
                <form onSubmit={handleSubmit} className="bg-green-900 p-6 rounded-lg shadow-lg">
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2 font-medium">Nome Completo</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="w-full p-3 bg-green-800 border border-green-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
                      placeholder="Seu nome"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="phone" className="block mb-2 font-medium">Telefone/WhatsApp</label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(formatPhone(e.target.value))}
                      required
                      className="w-full p-3 bg-indigo-800 border border-indigo-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="(00) 00000-0000"
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-2 font-medium">E-mail</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="w-full p-3 bg-indigo-800 border border-indigo-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="seu@email.com"
                    />
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label htmlFor="eventDate" className="block mb-2 font-medium">Data do Evento</label>
                      <input
                        type="date"
                        id="eventDate"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        required
                        className="w-full p-3 bg-indigo-800 border border-indigo-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="eventType" className="block mb-2 font-medium">Tipo de Evento</label>
                      <select
                        id="eventType"
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                        required
                        className="w-full p-3 bg-indigo-800 border border-indigo-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      >
                        <option value="">Selecione...</option>
                        <option value="Casamento">Casamento</option>
                        <option value="Aniversário">Aniversário</option>
                        <option value="Formatura">Formatura</option>
                        <option value="Corporativo">Corporativo</option>
                        <option value="Outro">Outro</option>
                      </select>
                    </div>
                  </div>
                  
                  <div className="mb-6">
                    <label htmlFor="duration" className="block mb-2 font-medium">Duração (horas)</label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        id="duration"
                        min="1"
                        max="8"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        className="w-full mr-4"
                      />
                      <span className="text-2xl font-bold">{duration}h</span>
                    </div>
                    <p className="mt-2 text-gray-300">
                      Total: <span className="line-through">R${500 * duration}</span>{' '}
                      <span className="text-green-400 font-bold">R${400 * duration}</span>
                    </p>
                  </div>
                  
                  <button 
                    type="submit" 
                    className="w-full bg-green-400 text-green-900 py-4 px-6 rounded-full font-bold text-lg hover:bg-green-300 transition transform hover:scale-105"
                  >
                    SOLICITAR COTAÇÃO VIA WHATSAPP
                  </button>
                  
                  <p className="mt-4 text-sm text-center text-gray-300">
                    Ao solicitar, você será redirecionado para o WhatsApp para finalizar sua reserva.
                  </p>
                </form>
              ) : (
                <div className="bg-green-900 p-6 rounded-lg shadow-lg text-center">
                  <CheckCircle size={64} className="text-green-400 mx-auto mb-4" />
                  <h3 className="text-2xl font-bold mb-2">Cotação enviada com sucesso!</h3>
                  <p className="mb-4">
                    Obrigado por seu interesse! Estamos redirecionando você para o WhatsApp para finalizar sua reserva e garantir o desconto exclusivo.
                  </p>
                  <p className="text-green-400 font-bold mb-6">
                    Lembre-se: Oferta válida por tempo limitado!
                  </p>
                  <button 
                    onClick={() => setIsFormSubmitted(false)} 
                    className="inline-block bg-white text-indigo-900 py-2 px-4 rounded font-medium"
                  >
                    Voltar ao formulário
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      
      {/* Footer */}
      <footer className="bg-green-900 py-8 px-4">
        <div className="container mx-auto max-w-6xl">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <Camera size={24} className="text-green-400" />
              <h2 className="text-xl font-bold">Foca no Zoom</h2>
            </div>
            
            <div className="flex items-center space-x-6">
              <a href="#" className="flex items-center text-gray-300 hover:text-green-400">
                <MessageCircle size={20} className="mr-2" />
                <span>Fale Conosco</span>
              </a>
              <a href="#" className="text-gray-300 hover:text-green-400">Instagram</a>
              <a href="#" className="text-gray-300 hover:text-green-400">Facebook</a>
            </div>
          </div>
          
          <div className="mt-6 pt-6 border-t border-green-800 text-center text-sm text-gray-400">
            &copy; {new Date().getFullYear()} Foca no Zoom • Todos os direitos reservados
          </div>
        </div>
      </footer>
      
      {/* Modal para remarketing */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-green-900 rounded-lg p-6 max-w-md w-full relative">
            <button 
              onClick={() => setShowModal(false)}
              className="absolute top-2 right-2 text-gray-400 hover:text-white"
            >
              ✕
            </button>
            
            <h3 className="text-2xl font-bold mb-4">Não perca esta oportunidade!</h3>
            <p className="mb-4">Deixe seu e-mail para receber um cupom de <span className="text-green-400 font-bold">10% OFF EXTRA</span> na sua reserva!</p>
            
            <div className="flex">
              <input 
                type="email" 
                placeholder="seu@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-1 p-3 bg-green-800 border border-green-700 rounded-l-lg focus:outline-none"
              />
              <button 
                onClick={() => {
                  localStorage.setItem('visitorEmail', email);
                  setShowModal(false);
                }}
                className="bg-green-400 text-green-900 px-4 py-3 rounded-r-lg font-bold"
              >
                ENVIAR
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Estilo para animação */}
      <style jsx>{`
        @keyframes marquee {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
        .animate-marquee {
          display: flex;
          animation: marquee 20s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
