// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import CatalogPage from './CatalogPage'; // Supondo que você tenha essa página
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import Budgets from './Budgets';
import BudgetDetails from './BudgetDetails'; // Página de detalhes do orçamento
// import AlinyAndCart from './AlinyAndCart'; // Página de detalhes do orçamento
import Jogo from './Jogo'; // Página de detalhes do orçamento
import Zoom from './zoom'; // Página de detalhes do orçamento

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/budgets" element={<Budgets />} />
        <Route path="/zoom" element={<Zoom />} />
        <Route path="/budget-details/:budgetId" element={<BudgetDetails />} /> {/* Página de detalhes do orçamento */}
        {/* <Route path="/aliny" element={<AlinyAndCart />} /> */}
        <Route path="/jogo" element={<Jogo />} />
      </Routes>
    </Router>
  );
}

export default App;
